import React, { useState } from 'react';

import { CategorySelectorMobile } from './mobile';
import { CategorySelectorDesktop } from './desktop';
import { CategorySelectorProps, SelectedActionAndCategory } from './types';
import { useBreakpoints } from '../../../hooks/useBreakpoints';

export const CategorySelector: React.FC<CategorySelectorProps> = ({ categories, actions }) => {
  const { isDesktop } = useBreakpoints();

  const [activeActionAndCategory, setActiveActionAndCategory] = useState<SelectedActionAndCategory>({
    action: actions[0].idAction,
    category: actions[0].idCategory,
  });

  const activeCategory = categories.find(category => category.idCategory === activeActionAndCategory.category);
  const activeAction = actions.find(action => action.idAction === activeActionAndCategory.action);

  if (!categories?.length || !activeCategory || !actions?.length || !activeAction) return null;

  const setCategory = (categoryId: string) => {
    const firstAction = actions.find(action => action.idCategory === categoryId);
    if (!firstAction) return;

    setActiveActionAndCategory({
      action: firstAction.idAction,
      category: categoryId,
    });
  };

  return isDesktop ? (
    <CategorySelectorDesktop
      categories={categories}
      actions={actions}
      activeAction={activeAction}
      activeCategory={activeCategory}
      setCategory={setCategory}
      setActiveActionAndCategory={setActiveActionAndCategory}
    />
  ) : (
    <CategorySelectorMobile
      categories={categories}
      actions={actions}
      activeAction={activeAction}
      activeCategory={activeCategory}
      setCategory={setCategory}
      setActiveActionAndCategory={setActiveActionAndCategory}
    />
  );
};
