import React from 'react';
import styled from '@emotion/styled';

export type SimpleCardTextOnlyProps = {
  title: string;
  text: string;
  background?: string;
  textAlign?: 'left' | 'center';
  uppercase?: boolean;
  margin?: string;
  padding?: string;
  titleColor?: string;
};

const Wrapper = styled.div<SimpleCardTextOnlyProps>`
  text-align: ${({ textAlign }) => textAlign};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  ${({ background }) => background && `background: ${background};̀`}
`;

const Title = styled.div<SimpleCardTextOnlyProps>`
  text-align: center;
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
  white-space: pre-wrap;
  font-size: ${({ theme }) => theme.font.size.h2};
  line-height: ${({ theme }) => theme.font.lineHeight.h2};

  ${({ titleColor }) => titleColor && `color: ${titleColor};̀`}
`;

const Description = styled.p`
  margin-top: 21px;
`;

export const SimpleCardTextOnly: React.FC<SimpleCardTextOnlyProps> = props => {
  return (
    <Wrapper {...props}>
      <Title {...props}>{props.title}</Title>
      <Description>{props.text}</Description>
    </Wrapper>
  );
};

SimpleCardTextOnly.defaultProps = {
  uppercase: true,
  textAlign: 'left',
  padding: '20px 40px',
};
