import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { SimpleCardTextOnly } from '../simple-card-text-only';
import { Icon } from '../../atoms/icon';
import { Heading } from '../../atoms/heading';
import { InnerCategorySelectorProps } from './types';
import { ActionContent } from './common';
import { useBreakpoints } from '../../../hooks/useBreakpoints';

const ListItems = styled.li`
  list-style: none;
`;

const MCategoryMenu = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .selected {
    color: ${({ theme }) => theme.color.palette['base-blue']};
    font-weight: bold;
  }
  ul {
    padding: 0;
    text-decoration: none;
    list-style-type: none;
    li {
      text-align: center;
      text-transform: uppercase;
      margin: 20px;
      cursor: pointer;
      color: grey;
    }
  }
`;

const ActionsHeader = styled.div`
  display: flex;
  height: 87px;
  border: solid ${({ theme }) => theme.color.palette['base-gray-2']} 1px;
  p {
    text-transform: uppercase;
  }
  .action-title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 87px;
    margin: 0 10px;
    color: ${({ theme }) => theme.color.palette['base-blue']};
  }
`;

const MenuHeader = styled.div`
  display: flex;
  border: solid ${({ theme }) => theme.color.palette['base-gray-2']} 1px;
  align-items: center;
  min-height: 87px;
`;

const CloseMenuWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 87px;
  width: 60px;
  margin-right: 10px;
`;

const OpenMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    width: 89px;
  }
  @media (min-width: 500px) {
    flex: 1;
  }
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid ${({ theme }) => theme.color.palette['base-gray-2']};
  padding-right: 10px;
  i {
    padding-left: 20px;
    padding-right: 10px;
  }
`;
const MenuTitle = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  color: ${({ theme }) => theme.color.palette['base-blue']};
`;

const Spacer = styled.div`
  display: flex;
  flex: 1;
  min-height: 10px;
`;

const ListWrapper = styled.div`
  width: 50%;
  padding: 20px;
  overflow-x: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

const UnorderedListMobile = styled.ul`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  margin: 0 0 0 100px;

  .selected {
    color: ${({ theme }) => theme.color.palette['base-blue']};
  }
`;

export const CategorySelectorMobile: React.FC<InnerCategorySelectorProps> = props => {
  const { categories, actions, activeCategory, activeAction, setCategory, setActiveActionAndCategory } = props;

  const intl = useIntl();
  const theme = useTheme();
  const { isMobile } = useBreakpoints();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const actionSentence = `${activeCategory?.title} ${activeAction.prefix} ${activeAction.title}`;

  return (
    <>
      {/* MCategoryMenu: menu to switch the category of action currently displayed */}
      {isMenuOpen && (
        <MCategoryMenu>
          <MenuHeader>
            <Spacer />
            <MenuTitle>
              <p>{activeCategory.title}</p>
            </MenuTitle>
            <Spacer>
              <CloseMenuWrapper onClick={() => setIsMenuOpen(false)}>
                <Icon kind="close" strokeColor="black" />
              </CloseMenuWrapper>
            </Spacer>
          </MenuHeader>
          <ul>
            {actions?.map(action => (
              <li key={action.idAction}>
                <div
                  role="button"
                  tabIndex={0}
                  className={action === activeAction ? 'selected' : ''}
                  onKeyPress={() => {
                    setActiveActionAndCategory({
                      action: action.idAction,
                      category: action.idCategory,
                    });
                    setIsMenuOpen(false);
                  }}
                  onClick={() => {
                    setActiveActionAndCategory({
                      action: action.idAction,
                      category: action.idCategory,
                    });
                    setIsMenuOpen(false);
                  }}
                >
                  <Heading level="h3">{action.title}</Heading>
                </div>
              </li>
            ))}
          </ul>
        </MCategoryMenu>
      )}
      {!isMenuOpen && (
        <>
          <ActionsHeader>
            {/* Button/Pane to open the MCategoryMenu and enable the switch of category */}
            <OpenMenuWrapper onClick={() => setIsMenuOpen(true)}>
              <Icon kind="actions" strokeColor="black" />
              {!isMobile && <p>{intl.formatMessage({ id: 'show_actions' })}</p>}
            </OpenMenuWrapper>
            <ListWrapper className="action-title">
              <UnorderedListMobile>
                {categories?.map(category => (
                  <ListItems key={category.idCategory}>
                    <button
                      className={category === activeCategory ? 'selected' : ''}
                      style={{ cursor: 'pointer', paddingTop: '15px' }}
                      onClick={() => setCategory(category.idCategory)}
                    >
                      {category.title}
                    </button>
                  </ListItems>
                ))}
              </UnorderedListMobile>
            </ListWrapper>
          </ActionsHeader>
          {/* Description of the current action */}
          <SimpleCardTextOnly
            title={actionSentence}
            text={activeCategory.description}
            textAlign="center"
            padding="50px 20px"
            background={theme.color.palette['base-purple']}
            titleColor={theme.color.palette['base-blue']}
          />

          {/* Description & content of the selected category for the current action */}
          <div style={{ padding: '0 20px', overflow: 'hidden' }}>
            {activeAction.content.map((content, index) => (
              <ActionContent key={`${content.type}_${index}`} {...content} />
            ))}
          </div>
        </>
      )}
    </>
  );
};
