import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';

import { parseLocale } from '../locale';
import SEO from '../components/seo';
import { OurImpactQuery } from '../../graphql-types';
import { OurImpactTemplate, OurImpactTemplateProps } from '../templates/our-impact.template';
import { mapFragmentToImageProps } from '../data-mappers/image.mapper';
import { mapFragmentToAlignedMarkdownProps } from '../data-mappers/markdown.mapper';
import { mapFragmentToCardOverlaidProps } from '../data-mappers/kpi.mapper';
import { CategorySelectorProps } from '../components/molecules/category-selector/types';
import { safeApply, safeMap } from '../data-mappers/utils';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

export const query = graphql`
  query OurImpact($language: String) {
    cms {
      ourImpact(locale: $language) {
        title
        metas {
          description
        }
        media {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        media_mobile {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        Introduction {
          ...AlignedMarkdown
        }
      }
      actionCategories(locale: $language) {
        id
        name
        description
        action_levers {
          id
          name
          linking_word
          Content {
            __typename
            ...AlignedMarkdown
            ...KpiCard
          }
        }
      }
    }
  }
`;

const OurImpactPage: React.FC<{ data: OurImpactQuery }> = ({ data }) => {
  const { cms } = data;

  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'our-impact' },
      { locale: 'fr', slug: 'notre-impact' },
    ],
  });

  const impacts: CategorySelectorProps = {
    actions: [],
    categories: [],
  };

  cms.actionCategories?.forEach(category => {
    if (!category) return;

    impacts.categories.push({
      idCategory: category.id,
      title: category.name ?? '',
      description: category.description ?? '',
    });

    category.action_levers?.forEach(action => {
      if (!action) return;

      impacts.actions.push({
        idCategory: category.id,
        idAction: action.id,
        title: action.name ?? '',
        prefix: action.linking_word ?? '',
        content: safeMap(content => {
          switch (content.__typename) {
            case 'cms_ComponentComponentAlignedMarkdown':
              return { type: 'alignMarkdown', props: mapFragmentToAlignedMarkdownProps(content) };

            case 'cms_ComponentComponentKpiCard':
              return { type: 'kpi', props: mapFragmentToCardOverlaidProps(content, locale) };
          }
        }, action?.Content),
      });
    });
  });

  const props: OurImpactTemplateProps = {
    title: cms.ourImpact?.title ?? '',
    image: mapFragmentToImageProps(cms.ourImpact?.media, locale, cms.ourImpact?.media_mobile),
    introduction: safeApply(value => mapFragmentToAlignedMarkdownProps(value), cms.ourImpact?.Introduction),
    impacts,
  };

  const seoProps = {
    lang: locale,
    description: '',
    title: cms?.ourImpact?.title ?? '',
  };

  return (
    <>
      <SEO {...seoProps} />
      <OurImpactTemplate {...props} />
    </>
  );
};

export default OurImpactPage;
