import React from 'react';
import { AlignedMarkdown } from '../../atoms/aligned-markdown';
import { CardOverlaidTextSingle } from '../../organisms/card-overlaid-text';
import { ActionContentProps } from './types';

export const ActionContent: React.FC<ActionContentProps> = content => {
  switch (content.type) {
    case 'alignMarkdown':
      return <AlignedMarkdown {...content.props} />;
    case 'kpi':
      return <CardOverlaidTextSingle {...content.props} />;
  }
};
