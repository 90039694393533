import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import styled from '@emotion/styled';
import { InnerCategorySelectorProps } from './types';
import { useDebouncedCallback } from 'use-debounce';
import { ActionContent } from './common';

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SubCategoryMenu = styled.div`
  width: 50%;
  height: 480px;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.palette['base-purple']};

  padding: 70px 20px 70px 50px;
`;

const CategoryMenu = styled.div`
  display: flex;
  min-height: 87px;
  align-items: center;
  text-align: center;
  border-top: solid ${({ theme }) => theme.color.palette['base-gray-2']} 1px;
  border-bottom: solid ${({ theme }) => theme.color.palette['base-gray-2']} 1px;

  .selected {
    color: ${({ theme }) => theme.color.palette['base-blue']};
    font-weight: bold;
  }
`;

const CategoryBtn = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 87px;
  cursor: pointer;
`;

const Prefix = styled.div`
  width: 50%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CategoryTitle = styled.div`
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.font.size.h3};
  line-height: ${({ theme }) => theme.font.lineHeight.h3};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.palette['base-blue']};
`;

const ListItems = styled.li`
  font-size: ${({ theme }) => theme.font.size.h3};
  line-height: ${({ theme }) => theme.font.lineHeight.h3};

  list-style: none;
  scroll-snap-align: start;

  button.selected {
    padding-top: 0;
    color: ${({ theme }) => theme.color.palette['base-blue']};
  }
`;

const DescriptionWrapper = styled.div`
  width: 50%;
  height: 480px;
  padding: 70px 100px;
  overflow-y: scroll;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const UnorderedList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 0;
  padding-left: 30px;

  scroll-snap-type: y mandatory;
  scroll-padding-top: 100px;
  overflow-y: scroll;
  scroll-behavior: smooth;

  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 100px,
    rgba(0, 0, 0, 1) 210px,
    rgba(0, 0, 0, 0) 75%
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 100px,
    rgba(0, 0, 0, 1) 140px,
    rgba(0, 0, 0, 0) 75%
  );

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &::before,
  ::after {
    content: '';
    flex: 0 0 50%;
    scroll-snap-align: none;
  }
`;

export const CategorySelectorDesktop: React.FC<InnerCategorySelectorProps> = props => {
  const { categories, actions, activeCategory, activeAction, setCategory, setActiveActionAndCategory } = props;

  const intl = useIntl();

  const actionsWithRef = useMemo(() => {
    return actions.map(action => {
      return {
        ...action,
        ref: React.createRef<HTMLLIElement>(),
      };
    });
  }, [actions]);

  const marginTop = 70;

  useEffect(() => {
    const foundAction = actionsWithRef.find(action => action.idAction === activeAction.idAction);

    if (!foundAction) return;
    const { ref } = foundAction;

    if (!ref.current?.parentElement) return;

    const top = ref.current.offsetTop - marginTop;
    ref.current.parentElement.scrollTo({ behavior: 'smooth', top });
  }, [actionsWithRef, activeAction]);

  const scrollEvent = useDebouncedCallback((node: any) => {
    const centeredAction = actionsWithRef.find(action => {
      // looking for the first action in the list that goes beyond the middle of the scroll
      const child = action.ref.current;
      if (!child) return false;

      return Math.ceil(child.offsetTop) >= node.scrollTop + marginTop;
    });

    // update the action if there is one
    if (centeredAction && centeredAction.idAction !== activeAction.idAction) {
      setActiveActionAndCategory({
        action: centeredAction.idAction,
        category: centeredAction.idCategory,
      });
    }
  }, 350);

  return (
    <>
      <CategoryMenu>
        {categories?.map(categ => (
          <CategoryBtn
            key={categ.title}
            onClick={() => setCategory(categ.idCategory)}
            title={`${intl.formatMessage({ id: 'see' })} '${categ.title}'`}
            className={categ === activeCategory ? 'selected' : undefined}
          >
            {categ.title}
          </CategoryBtn>
        ))}
      </CategoryMenu>
      <CategoryWrapper>
        <SubCategoryMenu>
          <Prefix>
            <CategoryTitle>
              {activeCategory.title} {activeAction.prefix}
            </CategoryTitle>
            <div>{activeCategory.description}</div>
          </Prefix>
          <UnorderedList onScroll={event => scrollEvent.callback(event.target)}>
            {actionsWithRef.map(action => (
              <ListItems key={action.idAction} ref={action.ref}>
                <button
                  onClick={() => {
                    setActiveActionAndCategory({
                      action: action.idAction,
                      category: action.idCategory,
                    });
                  }}
                  style={{ textTransform: 'uppercase', paddingBottom: '40px', textAlign: 'left' }}
                  className={action.title === activeAction.title ? 'selected' : ''}
                >
                  {action.title}
                </button>
              </ListItems>
            ))}
          </UnorderedList>
        </SubCategoryMenu>

        <DescriptionWrapper>
          {activeAction.content.map((content, index) => (
            <ActionContent key={`${content.type}_${index}`} {...content} />
          ))}
        </DescriptionWrapper>
      </CategoryWrapper>
    </>
  );
};
