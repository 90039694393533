import React from 'react';

import { AlignedMarkdown, AlignedMarkdownProps } from '../components/atoms/aligned-markdown';
import { Heading } from '../components/atoms/heading';
import { Image, ImageProps } from '../components/atoms/image';
import { CategorySelector } from '../components/molecules/category-selector';
import { CategorySelectorProps } from '../components/molecules/category-selector/types';
import { HeaderSection } from '../components/molecules/header-section';
import { Section } from '../components/molecules/section';
import { useBreakpoints } from '../hooks/useBreakpoints';

export type OurImpactTemplateProps = {
  title?: string;
  image?: ImageProps;
  introduction?: AlignedMarkdownProps;
  impacts?: CategorySelectorProps;
};

export const OurImpactTemplate: React.FC<OurImpactTemplateProps> = props => {
  const { isDesktop } = useBreakpoints();

  return (
    <>
      {props.title && (
        <HeaderSection>
          <Heading level="h1" css={{ textAlign: 'center' }} text={props.title} noMargin />
        </HeaderSection>
      )}

      <Section marginPreset={isDesktop ? 'bottom' : 'sides'} firstChildMarginPreset="bottom">
        <div>{props.image && <Image {...props.image} />}</div>
        {props.introduction && <AlignedMarkdown maxWidth="body_text" {...props.introduction} />}
      </Section>

      {props.impacts && <CategorySelector {...props.impacts} />}
    </>
  );
};
